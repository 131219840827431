import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Spin from "./utils/Spin.js";


const EmailSettings = React.lazy(() => import("./Pages/Users/EmailSettings.jsx"));
const Global = React.lazy(() => import("./Pages/Users/Global.js"));
const Dashboard = React.lazy(() => import("./Pages/Users/Dashboard.js"));
const Status = React.lazy(() => import("./Pages/Users/Status.js"));
const Profile = React.lazy(() => import("./Pages/Users/Profile.js"));
const Subscription = React.lazy(() => import("./Pages/Users/Subscription.js"));
const CardDetails = React.lazy(() => import("./Components/CardDetails.js"));
const InvoiceWrapper = React.lazy(() =>
  import("./Pages/Users/InvoiceWraper.js")
);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_P_KEY);

const UserRoutes = () => {
  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <Switch>
        <Elements stripe={stripePromise}>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/settings" component={Global} />
          <Route exact path="/status" component={Status} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/invoices" component={InvoiceWrapper} />
          <Route exact path="/emailSettings" component={EmailSettings} />
          <Route exact path="/cardDetails" component={CardDetails} />
          <Redirect from="/" to="/dashboard" />
        </Elements>
      </Switch>
    </Suspense>
  );
};

export default UserRoutes;
